<template>
  <div class="row mx-0 mt-4 justify-content-center">
    <div class="col-10 col-md-8 mb-3 text-center medium">
      You have already added donations to your cart, would you like to setup a recurring payment with these selected appeals, or start a new quick recurring donation?
    </div>
  </div>
</template>
<script>
export default {
  name: 'Recurring Items In Cart'
}
</script>
